import React from 'react'
import HomeFacilities from '../home/HomeFacilities'

const Facility = () => {
  return (
    <>
     <div>
        <div className="rts__section page__hero__height page__hero__bg" style={{ backgroundImage: 'url(assets/images/pages/header__bg.webp)' }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12">
                <div className="page__hero__content">
                  <h1 className="">Facility</h1>
                  <p className=" font-sm">Welcome to Bokinn, where luxury meets comfort in the heart of
                    canada.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      <HomeFacilities />

      
      </div>
    
    </>
  )
}

export default Facility