import React from 'react'
import SliderOurRooms from '../../component/slidercomponent/SliderOurRooms'

const OurRooms = () => {
    return (
        <>

            {/* <div className="rts__section pt-120">
                <div className="container">
                    <div className="row">
                        <div className="section__wrapper mb-40  animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                            <div className="section__content__left">
                                <span className="h6 subtitle__icon__two d-block  animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Room</span>
                                <h2 className="content__title h2 lh-1">Our Rooms</h2>
                            </div>
                            <div className="section__content__right">
                                <p style={{ textAlign: "justify" }}>The room can accommodate two adults and two children.
                                    These rooms, which are on the resort's eastern wing, are about 310 square feet in size. Over two stories, there are thirty-five rooms. Every room has a sofa that doubles as a bed in addition to a king-size bed. A cable-equipped LED TV is also included in the rooms.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="main__room__slider overflow-hidden  swiper-backface-hidden animated" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>


                        <div className="" id="swiper-wrapper-51045ef47795281d6" aria-live="polite" style={{ transitionDuration: '0ms', transform: 'translate3d(-895.333px, 0px, 0px)', transitionDelay: '0ms' }}>

                            <div className="swiper-slide" role="group" aria-label="1 / 5" data-swiper-slide-index={0} style={{ width: '417.667px', marginRight: 30 }}>
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <div id="jarallax-container-1" className="jarallax-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: -100, clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)' }}><img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/1.webp" alt style={{ objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'absolute', top: 0, left: 0, width: '417.656px', height: 502, overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', marginTop: '-41.5px', transform: 'translate3d(0px, 44px, 0px)' }} /></div></div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Deluxe Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" /> 2 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide swiper-slide-prev" role="group" aria-label="2 / 5" data-swiper-slide-index={1} style={{ width: '417.667px', marginRight: 30 }}>
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <div id="jarallax-container-2" className="jarallax-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: -100, clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)' }}><img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/2.webp" alt style={{ objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'absolute', top: 0, left: 0, width: '417.656px', height: 502, overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', marginTop: '-41.5px', transform: 'translate3d(0px, -155px, 0px)' }} /></div></div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Executive Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />3 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide swiper-slide-active" role="group" aria-label="3 / 5" data-swiper-slide-index={2} style={{ width: '417.667px', marginRight: 30 }}>
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <div id="jarallax-container-3" className="jarallax-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: -100, clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)' }}><img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/3.webp" alt style={{ objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'absolute', top: 0, left: 0, width: '417.656px', height: 502, overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', marginTop: '-41.5px', transform: 'translate3d(0px, -165px, 0px)' }} /></div></div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Suite Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />3 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide swiper-slide-next" role="group" aria-label="4 / 5" data-swiper-slide-index={3} style={{ width: '417.667px', marginRight: 30 }}>
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <div id="jarallax-container-4" className="jarallax-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: -100, clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)' }}><img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/4.webp" alt style={{ objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'absolute', top: 0, left: 0, width: '417.656px', height: 502, overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', marginTop: '-41.5px', transform: 'translate3d(0px, -165px, 0px)' }} /></div></div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Deluxe Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />2 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide" role="group" aria-label="5 / 5" data-swiper-slide-index={4} style={{ width: '417.667px', marginRight: 30 }}>
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <div id="jarallax-container-5" className="jarallax-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: -100, clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)' }}><img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/1.webp" alt style={{ objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'absolute', top: 0, left: 0, width: '417.656px', height: 502, overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', marginTop: '-41.5px', transform: 'translate3d(0px, -165px, 0px)' }} /></div></div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Executive Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />3 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span className="swiper-notification" aria-live="assertive" aria-atomic="true" />
                    </div>
                    <div className="rts__pagination">
                        <div className="rts-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 1" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 2" /><span className="swiper-pagination-bullet swiper-pagination-bullet-active" tabIndex={0} role="button" aria-label="Go to slide 3" aria-current="true" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 4" /><span className="swiper-pagination-bullet" tabIndex={0} role="button" aria-label="Go to slide 5" /></div>
                    </div>
                </div>
            </div> */}



            <div className="rts__section pt-120">
                <div className="container">
                    <div className="row">
                        <div className="section__wrapper mb-40 ">
                            <div className="section__content__left">
                                <span className="h6 subtitle__icon__two d-block ">Room</span>
                                <h2 className="content__title h2 lh-1">Our Rooms</h2>
                            </div>
                            <div className="section__content__right">
                                <p style={{ textAlign: "justify" }}>The room can accommodate two adults and two children.
                                    These rooms, which are on the resort's eastern wing, are about 310 square feet in size. Over two stories, there are thirty-five rooms. Every room has a sofa that doubles as a bed in addition to a king-size bed. A cable-equipped LED TV is also included in the rooms.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="main__room__slider overflow-hidden " data-wow-delay=".5s">
                        <div className="swiper-wrapper">
                            <div className="swiper-slide">
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/1.webp" alt />
                                    </div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Deluxe Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" /> 2 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/2.webp" alt />
                                    </div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Executive Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />3 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/3.webp" alt />
                                    </div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Suite Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />3 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/4.webp" alt />
                                    </div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Deluxe Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />2 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="room__slide__box radius-6">
                                    <div className="room__thumbnail jara-mask-2 jarallax">
                                        <img height={585} width={420} className="radius-6 jarallax-img" src="assets/images/room/1.webp" alt />
                                    </div>
                                    <div className="room__content">
                                        <a to="room-details-1.html" className="room__title">
                                            <h5>Executive Rooms</h5>
                                        </a>
                                        <div className="room__content__meta">
                                            <span><i className="flaticon-construction" />3 Adults</span>
                                            <span><i className="flaticon-user" />2 Children</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rts__pagination">
                        <div className="rts-pagination" />
                    </div>
                </div>
            </div>


        </>
    )
}

export default OurRooms