import React from 'react'

const HomeFacilities = () => {
    return (
        <>

            <div className="rts__section  facility__area has__background has__shape">
                <div className="section__shape wow fadeInRight animated" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInRight' }}>
                    <img src="assets/images/index-3/facility-shape.svg" alt />
                </div>
                <div className="container">
                    <div className="row g-5 align-items-center">
                        <div className="col-lg-6">
                            <div className="facility__image jara-mask-3 jarallax">
                                <div id="jarallax-container-0" className="jarallax-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', zIndex: -100, clipPath: 'polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%)' }}><img height={765} width={605} className="jarallax-img" src="assets/images/index-3/facility.webp" alt style={{ objectFit: 'cover', objectPosition: '50% 50%', maxWidth: 'none', position: 'absolute', top: 0, left: 0, width: 534, height: 592, overflow: 'hidden', pointerEvents: 'none', transformStyle: 'preserve-3d', backfaceVisibility: 'hidden', marginTop: '-86.5px', transform: 'translate3d(0px, 150px, 0px)' }} /></div></div>
                        </div>
                        <div className="col-lg-6">
                            <div className="facility__content">
                                <div className="section__topbar">
                                    <span className="h6 subtitle__icon__five d-block  animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Facility</span>
                                    <h2 className="content__title h2 lh-1  animated" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Hotel Uma Residency </h2>
                                </div>
                                <div className="row mt-20 g-5  animated" data-wow-delay=".5s" style={{ visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp' }}>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="card rts__card no-border is__home__three">
                                            <div className="card-body">
                                                <div className="icon"><img src="assets/images/icon/bed.svg" alt /></div>
                                                <a to="#">
                                                    <h6 className="card-title h6 mb-15">Deluxe Rooms</h6>
                                                </a>
                                                <p className="card-text" style={{ textAlign: "justify" }}>Varied types of rooms, from standard to Deluxe Rooms, equipped with essentials like beds.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="card rts__card no-border is__home__three">
                                            <div className="card-body">
                                                <div className="icon"><img src="assets/images/icon/security.svg" alt /></div>
                                                <a to="#">
                                                    <h6 className="card-title h6 mb-15" style={{ textAlign: "justify" }}>24-Hour Security</h6>
                                                </a>
                                                <p className="card-text">On-site security personnel and best surveillance. from standard to luxury suites,Secure storage for valuables.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="card rts__card no-border is__home__three">
                                            <div className="card-body">
                                                <div className="icon"><img src="assets/images/icon/bed.svg" alt /></div>
                                                <a to="#">
                                                    <h6 className="card-title h6 mb-15">Executive Rooms</h6>
                                                </a>
                                                <p className="card-text" style={{ textAlign: "justify" }}>Varied types of rooms, from standard to Executive Rooms, equipped with essentials like beds.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="card rts__card no-border is__home__three">
                                            <div className="card-body">
                                                <div className="icon"><img src="assets/images/icon/bed.svg" alt /></div>
                                                <a to="#">
                                                    <h6 className="card-title h6 mb-15">Suite Rooms</h6>
                                                </a>
                                                <p className="card-text" style={{ textAlign: "justify" }}>Varied types of rooms, from standard to Suite Rooms, equipped with essentials like beds.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}

export default HomeFacilities