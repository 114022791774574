import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoMdClose } from 'react-icons/io';
import emailjs from "@emailjs/browser";




const Hadear = () => {
  const [toggle, setToggle] = useState(false);

  const CloseClick = () => {
    setToggle(false);

  };
  const [ismobile, setIsmobile] = useState(0);

  // const { categorytotal } = useSelector((store) => store.categoryAll);


  const [showAbout, setShowAbout] = useState(true);
  const [showInterior, setShowInterior] = useState(true);
  const [showGallery, setShowGallery] = useState(true);

  const closemenu = () => {
    document.getElementById("mytogglebtn").classList.add("collapsed");
    document.getElementById("menu").classList.remove("in");
  };



  const [name, setName] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [subject, setSubject] = useState("");
  const [city, setCity] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const stateChange = (e) => {
    const value = e.target.value;
    setState(value);
  };
  const cityChange = (e) => {
    const value = e.target.value;
    setCity(value);
  };
  const subjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
  };
  const numberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
  };

  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(

      // "service_oumku8k",
      // "template_esy4tqm",
      // "#myForm",
      // "8i7i_APkS7sZ9CqPl"
    )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setmail("");
            setMassages("");
            setNumber("");
            setState("");
            setSubject("");
            setCity("");

          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };


  return (
    <>

      <div className="header__top">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-6 col-md-6">
              <div className="social__links">
                <a className="link__item gap-10" href="callto:+918871112222"><i className="flaticon-phone-flip" />
                  +91 8871112222</a>
                <a className="link__item gap-10" href="mailto:reservations@hotelumaresidency.com"><i className="flaticon-envelope" />
                  reservations@hotelumaresidency.com</a>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="location">
                <a className="link__item gap-10" href="https://maps.app.goo.gl/a3k49kd3XPjBz7nR9" target="_blank"><i className="flaticon-marker" />Rewa Rd, ChankyaPuri Colony, Satna, Madhya Pradesh 485001</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header className="header-area stricky" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="outer-box clearfix">
                <div className="logo">
                  <Link to="/">
                    <img src="assets/Hotel Uma Residency logo.jpg" alt="Awesome Logo" style={{ width: "110px", height: "70px" }} />
                    {/* ./assets/public/website/assets/images/Untitled design (12).png */}
                  </Link>
                </div>
                <nav className="main-menu">
                  <div className="navbar-header">
                    <button
                      id="mytogglebtn"
                      v
                      className="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#menu"
                      onClick={closemenu}
                    >
                      <span className="icon-bar" />
                      <span className="icon-bar" />
                      <span className="icon-bar" />

                    </button>
                  </div>
                  <div className="navbar-collapse collapse clearfix" id="menu">
                    <ul className="navigation clearfix" style={{ fontFamily: "initial" }} >
                      <li className="current" >
                        <Link to="/" onClick={closemenu} className=''>
                          Home
                        </Link>
                      </li>
                      <li className="" >
                        <Link to="/about-us" onClick={closemenu}>
                          About Us
                        </Link>
                      </li>
                      {/* <li className="dropdown">
                        <Link onClick={closemenu}>Service</Link>
                        <ul
                          style={
                            showAbout
                              ? { display: "block" }
                              : { display: "none" }
                          }
                        >

                          <li>
                            <Link to="/corporate-events" onClick={closemenu}>
                              Corporate Events
                            </Link>
                          </li>
                          <li>
                            <Link to="/school-trips" onClick={closemenu}>
                              School Trips
                            </Link>
                          </li>
                          <li>
                            <Link to="/sports-teams" onClick={closemenu}>
                              Sports Teams
                            </Link>
                          </li>
                          <li>
                            <Link to="/wedding-shuttles" onClick={closemenu}>
                              Wedding Shuttles
                            </Link>
                          </li>
                        </ul>

                        <div
                          className="dropdown-btn"
                          onClick={() => setShowAbout(!showAbout)}
                        ></div>
                      </li> */}

                      <li className="">
                        <Link to="/facility" onClick={closemenu}>
                        Facility
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/gallery" onClick={closemenu}>
                        Our Rooms
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/gallery" onClick={closemenu}>
                        Gallery
                        </Link>
                      </li>
                      <li className="">
                        <Link to="/contact" onClick={closemenu}>
                        Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>


    </>
  )
}

export default Hadear