import React from 'react'
import { Link } from "react-router-dom";
import WhatsappIcon from '../../pages/WhatsappIcon';


const Footer = () => {
  return (
    <>
      <footer className="rts__section rts__footer is__common__footer footer__background has__shape">
        <div className="section__shape">
          <div className="shape__1">
            <img src="assets/images/footer/shape-1.svg" alt />
          </div>
          <div className="shape__2">
            <img src="assets/images/footer/shape-2.svg" alt />
          </div>
          <div className="shape__3">
            <img src="assets/images/footer/shape-3.svg" alt />
          </div>
        </div>
        <div className="container">

          <div className="row">
            <div className="footer__widget__wrapper">
              <div className="rts__widget">
                <a to="index.html"><img className="footer__logo" src="assets/Hotel_Uma_Residency_logo.png" alt="footer logo" style={{ width: "200px", height: "120px" }} /></a>
                <p className="font-sm max-290 mt-20" style={{ textAlign: "justify" }}>
                  At Hotel Uma Residency, we think it's important to acknowledge our loyal guests, greet newcomers, and treat everyone the way we want to be treated.
                </p>
              </div>
              <div className="rts__widget">
                <span className="widget__title">Quick Links</span>
                <ul>
                  <li><Link to="/" aria-label="footer__link"> Home</Link> </li>
                  <li><Link to="/about-us" aria-label="footer__link">About Us</Link></li>
                  <li><a to="#" aria-label="footer__link">Facility</a></li>
                  <li><a to="#" aria-label="footer__link">Our Rooms</a></li>
                  <li><Link to="/gallery" aria-label="footer__link">Gallery</Link></li>
                </ul>
              </div>
              <div className="rts__widget">
                <span className="widget__title">Guest Service</span>
                <ul>
                  <li><Link to="/contact" aria-label="footer__link">Contact Us</Link></li>
                  <li>24/7 Front Desk</li>
                  <li>Parking</li>
                  <li>Room Service</li>
                  <li>Free Wi-Fi</li>
                </ul>
              </div>
              <div className="rts__widget">
                <span className="widget__title">Contact Us</span>
                <ul>
                  <li><a aria-label="footer__contact" href="tel:+918871112222"><i className="flaticon-phone-flip" /> +91 8871112222</a></li>
                  <li><a aria-label="footer__contact" href="mailto:reservations@hotelumaresidency.com"><i className="flaticon-envelope" />reservations@hotelumaresidency.com</a></li>
                  <li><a aria-label="footer__contact" href="https://maps.app.goo.gl/ZkqoPbfw9r8GDVdv5" target="_blank"><i className="flaticon-marker" />Rewa Rd, ChankyaPuri Colony, <br />Satna, Madhya Pradesh 485001</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright__text">
          <div className="container">
            <div className="row">
              <div className="copyright__wrapper">
                <p className="mb-0">2024 © Hotel Uma Residency | Designed by  <a href='tel:+918982471422' style={{ color: "#65676B" }}> Web solution & digital marketing.</a></p>
                <div className="footer__social__link">
                  <a to="#" aria-label="footer__social" className="link__item">Facebook</a>
                  <a to="#" aria-label="footer__social" className="link__item">Linkedin</a>
                  <a to="#" aria-label="footer__social" className="link__item">Twitter</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>





      <WhatsappIcon />

    </>
  )
}

export default Footer