import React from 'react'
import { Link } from "react-router-dom";


const Gallery = () => {
    return (
        <>
            <div>
                <div className="rts__section page__hero__height page__hero__bg" style={{ backgroundImage: 'url(assets/images/pages/header__bg.webp)' }}>
                    <div className="container">
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12">
                                <div className="page__hero__content">
                                    <h1 className="wow fadeInUp">Gallery</h1>
                                    <p className="wow fadeInUp font-sm">where every image tells a story of luxury, comfort, and
                                        unparalleled hospitality</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rts__section section__padding">
                    <div className="container">
                        <div className="row g-30 gallery">
                            <div className="col-lg-4 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/1.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/1.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/2.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/2.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/3.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/3.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/4.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/4.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/5.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/5.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/6.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/6.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/7.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/7.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="gallery__item">
                                    <a to="assets/images/pages/gallery/8.webp" className="gallery__link">
                                        <img className="rounded-2 img-fluid" src="assets/images/pages/gallery/8.webp" alt="gallery" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Gallery