import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';

import { Link } from "react-router-dom";


export default function SliderTestimonials() {
    return (
        <>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}

                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                breakpoints={{
                    '@0.00': {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                    '@0.75': {
                        slidesPerView: 1,
                        spaceBetween: 20,
                    },
                    '@1.00': {
                        slidesPerView: 1,
                        spaceBetween: 40,
                    },
                    '@1.50': {
                        slidesPerView: 1,
                        spaceBetween: 50,
                    },
                }}
                modules={[Autoplay]}
                className="mySwiper"
            >


                <SwiperSlide>
                    <div className="swiper-slide swiper-slide-prev" role="group" aria-label="3 / 3" data-swiper-slide-index={2} style={{ width: 926 }}>
                        <div className="single__slider__item is__home ">
                            <div className="slider__rating mb-30">
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star-sharp-half-stroke" />
                            </div>
                            <span className="slider__text d-block">This is my 3rd stay here with my parents, and they never disappoint. Everyone working here is very cooperative and polite. Special words of praise for the Duty Manager, Sayantan, who immediately initiated the process of my refund after I had to cut short my trip. Can't say enough about the great location right in the heart of the city.</span>
                            <div className="slider__author__info">
                                <div className="slider__author__info__image">
                                    <img src="assets/images/author/Profile.png" alt />
                                </div>
                                <div className="slider__author__info__content">
                                    <h6 className="mb-0">Sarah Martinez</h6>
                                    <span>COO of Apex Solutions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide swiper-slide-active" role="group" aria-label="1 / 3" data-swiper-slide-index={0} style={{ width: 926 }}>
                        <div className="single__slider__item is__home ">
                            <div className="slider__rating mb-30">
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star-sharp-half-stroke" />
                            </div>
                            <span className="slider__text d-block">Great place to stay - it has courteous staff, inviting rooms, smooth check-in & check-out, & proximity to local market and eating options as well as to locations such as Eden, Victoria Memorial, and Indian Museum. Breakfast buffet is good and serves a variety of options (good option for picky eaters as well as for families travelling with kids).Easy to get cab from the hotel gate at reasonable prices.</span>
                            <div className="slider__author__info">
                                <div className="slider__author__info__image">
                                    <img src="assets/images/author/Profile.png" alt />
                                </div>
                                <div className="slider__author__info__content">
                                    <h6 className="mb-0">Sarah Martinez</h6>
                                    <span>COO of Apex Solutions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="swiper-slide swiper-slide-next" role="group" aria-label="2 / 3" data-swiper-slide-index={1} style={{ width: 926 }}>
                        <div className="single__slider__item is__home ">
                            <div className="slider__rating mb-30">
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star" />
                                <i className="flaticon-star-sharp-half-stroke" />
                            </div>
                            <span className="slider__text d-block">Amusing place. Great location. Rooms in the new wing are clean, bathroom is clean, towels are fresh and clean. Water is not bottled, but in glass bottles. They won't get you extra bottles of water, but will refill the same bottles which are in the room. No shower gel, only whatever shampoo. Super slow check in experience. They take in the money for your stay, right at check in.</span>
                            <div className="slider__author__info">
                                <div className="slider__author__info__image">
                                    <img src="assets/images/author/Profile.png" alt />
                                </div>
                                <div className="slider__author__info__content">
                                    <h6 className="mb-0">Sarah Martinez</h6>
                                    <span>COO of Apex Solutions</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    );
}
