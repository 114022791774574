import React from 'react'
import HomeBanner from './home/HomeBanner'
import AdvanceSearch from './home/AdvanceSearch'
import HomeAbout from './home/HomeAbout'
import HomeFacilities from './home/HomeFacilities'
import OurRooms from './home/OurRooms'
import Testimonial from './home/Testimonial'

const Home = () => {
  return (
    <>
      <HomeBanner />
      <AdvanceSearch />
      <HomeAbout />
      <OurRooms />
      <HomeFacilities />
      <Testimonial />
     
     

    </>
  )
}

export default Home