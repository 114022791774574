import React from 'react'

const HomeAbout = () => {
    return (
        <>

            <div className="rts__section about__area is__home__main section__padding">
                <div className="section__shape d-none d-xl-block">
                    <img src="assets/images/about/section__shape.svg" alt />
                </div>
                <div className="container">
                    <div className="row">
                        <div className="about__wrapper">
                            <div className="image ">
                                <div className="position-relative" data-wow-delay=".3s">
                                    <div className="jara-mask-1 jarallax image-height">
                                        <img src="assets/images/about/about-1.webp" className="jarallax-img" alt />
                                    </div>
                                </div>
                                <div className="image__card radius-10" data-wow-delay=".5s">
                                    <div className="icon radius-10 center-item">
                                        <i className="flaticon-people" style={{fontSize:"-2000px"}} />
                                    </div>
                                    <div className="content">
                                        <span className="h5">24+</span>
                                        <p>Experience</p>
                                    </div>
                                </div>
                                <div className="image__card__image ">
                                    <img src="assets/images/about/about-main.webp" width={312} height={230} alt />
                                </div>
                            </div>
                            <div className="content">
                                <span className="h6 subtitle__icon__two d-block ">About Us</span>
                                <h2 className="content__title ">Welcome to Hotel Uma Residency</h2>
                                <p className="content__subtitle " data-wow-delay=".3s" style={{textAlign:"justify"}}>At Hotel Uma Residency, we think it's important to acknowledge our loyal guests, greet newcomers, and treat everyone the way we want to be treated. Since we think our goal is to leave a lasting impression, we value every individual that interacts with us. We think that when we genuinely connect with the people and the things around us, life is richer. We would do everything in our power to make your visit luxurious, cozy, and secure.</p>
                                <a to="#" className="theme-btn btn-style fill no-border" data-wow-delay=".5s"><span>Learn More</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeAbout