import React, { useState } from 'react'
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";



const Contact = () => {

  const [name, setName] = useState("");
  const [mail, setmail] = useState("");
  const [massages, setMassages] = useState("");
  const [number, setNumber] = useState("");
  const [state, setState] = useState("");
  const [subject, setSubject] = useState("");
  const [city, setCity] = useState("");

  const nameChange = (e) => {
    const value = e.target.value;
    setName(value);
  };
  const stateChange = (e) => {
    const value = e.target.value;
    setState(value);
  };
  const cityChange = (e) => {
    const value = e.target.value;
    setCity(value);
  };
  const subjectChange = (e) => {
    const value = e.target.value;
    setSubject(value);
  };
  const numberChange = (e) => {
    const value = e.target.value;
    setNumber(value);
  };

  const mailChange = (e) => {
    const value = e.target.value;
    setmail(value);
  };
  const massagesChange = (e) => {
    const value = e.target.value;
    setMassages(value);
  };


  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(

        "service_g64mytg",
        "template_281srbf",
        "#myForm",
        "fpq6WzMGTknIEGaq7"
      )
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          if (response.text === "OK") {
            setName("");
            setmail("");
            setMassages("");
            setNumber("");
            setState("");
            setSubject("");
            setCity("");

          }
        },
        function (error) {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <>
      <div>
        <div className="rts__section page__hero__height page__hero__bg" style={{ backgroundImage: 'url(assets/images/pages/header__bg.webp)' }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12">
                <div className="page__hero__content">
                  <h1 className="wow fadeInUp">Contact Us</h1>
                  <p className="wow fadeInUp font-sm">Whether you have questions, need assistance, or simply want to
                    share.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rts__section section__padding">
          <div className="container">
            <div className="row g-30 align-items-center">
              <div className="col-lg-6">
                <div className="rts__contact">
                  <span className="h4 d-block mb-30 text-center">Love to hear from you
                    Get in touch!</span>
                  <form action="mailer.php" method="post" className="rts__contact__form" id="contact-form">
                    <div className="form-input">
                      <label htmlFor="name">Your Name</label>
                      <div className="pr">
                        <input type="text" id="name" name="name" placeholder="Your Name" required />
                        <i className="flaticon-user" />
                      </div>
                    </div>
                    <div className="form-input">
                      <label htmlFor="email">Your Email</label>
                      <div className="pr">
                        <input type="email" id="email" name="email" placeholder="Your Email" required />
                        <i className="flaticon-envelope" />
                      </div>
                    </div>
                    <div className="form-input">
                      <label htmlFor="msg">Your Message</label>
                      <div className="pr">
                        <textarea id="msg" name="msg" placeholder="Message" required defaultValue={""} />
                        <img src="assets/images/icon/message.svg" width={20} height={20} alt />
                      </div>
                    </div>
                    <button type="submit" className="theme-btn btn-style fill w-100"><span>Send
                      Message</span></button>
                  </form>
                  <div id="form-messages" className="mt-20" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact__image">
                  <img className="rounded-2 w-100 img-fluid" src="assets/images/pages/contact.webp" width={645} height={560} alt="contact__image" />
                </div>
              </div>
            </div>
          </div>
          <div className="container pt-120">
            <div className="row g-30 align-items-center">
              <div className="col-lg-6">
                <div className="contact__map">
                  <div style={{ maxWidth: '100%', overflow: 'hidden', color: 'red', width: 500, height: 200 }}><div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}><iframe style={{ height: '100%', width: '100%', border: 0 }} frameBorder={0} src="https://www.google.com/maps/embed/v1/place?q=Hotel+Uma+Residency+Rewa+Rd,+ChankyaPuri+Colony,+Satna,+Madhya+Pradesh+485001&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" /></div><a className="google-map-html" href="https://www.bootstrapskins.com/themes" id="authorize-maps-data">premium bootstrap themes</a><style dangerouslySetInnerHTML={{ __html: "#embed-map-canvas img.text-marker{max-width:none!important;background:none!important;}img{max-width:none}" }} /></div>

                </div>
              </div>
              <div className="col-lg-6">
                <div className="contact__info">
                  <div className="contact__info__item">
                    <h4>Hotel location</h4>
                    <p>
                      Address: <a aria-label="footer__contact" href="https://maps.app.goo.gl/ZkqoPbfw9r8GDVdv5" style={{ color: "#65676B" }} target="_blank">Rewa Rd, ChankyaPuri Colony, <br />Satna, Madhya Pradesh 485001</a> <br />
                      Telephone:&nbsp;  <a href="tel:+918871112222" style={{ color: "#65676B" }}>+91 8871112222</a> <br />
                      Email:  <a aria-label="footer__contact" href="mailto:reservations@hotelumaresidency.com" style={{ color: "#65676B" }}>reservations@hotelumaresidency.com</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default Contact