import React from 'react'
import { Link } from "react-router-dom";
import OurRooms from '../home/OurRooms';
import HomeFacilities from '../home/HomeFacilities';
import Testimonial from '../home/Testimonial';


const AboutUs = () => {
  return (
    <>
      <div>
        <div className="rts__section page__hero__height page__hero__bg" style={{ backgroundImage: 'url(assets/images/pages/header__bg.webp)' }}>
          <div className="container">
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-12">
                <div className="page__hero__content">
                  <h1 className="">About Us</h1>
                  <p className=" font-sm">Welcome to Bokinn, where luxury meets comfort in the heart of
                    canada.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rts__section about is__home__four section__padding">
          <div className="container">
            <div className="row g-4 align-items-center  ">
              <div className="col-lg-5">
                <div className="about__images">
                  <div className="image__left ">
                    <img height={474} width={450} src="assets/images/index-4/about/1.webp" alt />
                  </div>
                  <div className="image__right ">
                    <img width={365} height={385} src="assets/images/index-4/about/2.webp" alt />
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="about__content">
                  <span className="h6 subtitle__icon__two d-block ">About Us</span>
                  <h2 className="font-xl ">Welcome to Hotel Uma Residency</h2>
                  <p className="font-sm mt-30 " data-wow-delay=".3s" style={{textAlign:'justify'}}>
                  At Hotel Uma Residency, we think it's important to acknowledge our loyal guests, greet newcomers, and treat everyone the way we want to be treated. Since we think our goal is to leave a lasting impression, we value every individual that interacts with us. We think that when we genuinely connect with the people and the things around us, life is richer. We would do everything in our power to make your visit luxurious, cozy, and secure.
                  </p>
                  <p className="font-sm mt-30 " data-wow-delay=".3s" style={{textAlign:'justify'}}> A magnificent lobby is a sight to behold and greets you to the best hospitality experience. With 57 rooms that can accommodate a large group of people, the property is stretched across 6 acres. Its spacious three event rooms, multi-cuisine restaurant, toddler-friendly pool, and two sprawling lawns with glossy greenery all contribute to a comfortable stay. You have access to a variety of contemporary conveniences to guarantee a pleasurable stay. We provide everything that a five-star hotel does, including cozy accommodations, culinary options, and expansive event spaces. </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HomeFacilities />
      <Testimonial />
      <OurRooms />
    </>
  )
}

export default AboutUs